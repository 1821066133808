import { EVENT_MOUSEDOWN, EVENT_MOUSEMOVE, EVENT_MOUSEUP, Plane, Ray, registerScript, ScriptType, Vec2, Vec3 } from "playcanvas";

export class DragAndDrop extends ScriptType {
    static register() {
        this.setDefaultPrototype();
        registerScript(DragAndDrop, "dragAndDrop");
    }

    static setDefaultPrototype() {
        // initialize code called once per entity
        DragAndDrop.prototype.initialize = function () {
            this.app.mouse.on(EVENT_MOUSEDOWN, this.selecteModel, this);
            this.app.mouse.on(EVENT_MOUSEMOVE, this.drag, this);
            this.app.mouse.on(EVENT_MOUSEUP, this.drop, this);

            this.on(
                "destroy",
                function () {
                    this.app.mouse.off(EVENT_MOUSEDOWN, this.selecteModel, this);
                    this.app.mouse.off(EVENT_MOUSEMOVE, this.drag, this);
                    this.app.mouse.off(EVENT_MOUSEUP, this.drop, this);
                },
                this
            );
            this.pickerScale = 0.25;
            this.dragging = false;
            this.pickedEntity = null;
            this.ray = new Ray();
            this.planeEntity = null;
            this.hitPosition = new Vec3();
            this.offset = new Vec3();
        };

        // @ts-ignore
        DragAndDrop.prototype.selecteModel = function (event) {
            if (!this.dragging) {
                // create raycast to check if there is an entity to drag
                const start = this.entity.camera.screenToWorld(
                    event.x,
                    event.y,
                    this.entity.camera.nearClip
                );
                const end = this.entity.camera.screenToWorld(
                    event.x,
                    event.y,
                    this.entity.camera.farClip
                );

                // check for pickable entity
                const result = this.app.systems.rigidbody.raycastFirst(start, end);

                if (result && result.entity.tags.has('pickable')) {
                    // start dragging
                    this.dragging = true;
                    // get entity
                    this.pickedEntity = result.entity;
                    // Create moving plane
                    this.planeEntity = new Plane(this.pickedEntity.getLocalPosition(), this.pickedEntity.up.clone());
                    const screenPos = new Vec3();
                    this.entity.camera.worldToScreen(this.pickedEntity.getLocalPosition(), screenPos);
                    this.offset = new Vec3().sub2(screenPos, event);
                }
            }
        };

        // @ts-ignore
        DragAndDrop.prototype.drag = function (event) {
            if (this.dragging && this.pickedEntity) {
                this.entity.camera.screenToWorld(event.x + this.offset.x, event.y + this.offset.y, this.entity.camera.farClip, this.ray.direction);
                this.ray.origin.copy(this.entity.getPosition());
                this.ray.direction.sub(this.ray.origin).normalize();

                const isIntersectPlane = this.planeEntity.intersectsRay(this.ray, this.hitPosition);
                if (isIntersectPlane) {
                    this.pickedEntity.setLocalPosition(this.hitPosition);
                    this.pickedEntity.rigidbody.type = 'kinematic';
                    this.entity.fire('onDraggingModel');
                }
            }
        };

        // @ts-ignore
        DragAndDrop.prototype.drop = function (event) {
            if (this.dragging) {
                this.pickedEntity.rigidbody.type = 'dynamic';
                this.pickedEntity = null;
                this.dragging = false;
            }
        };
    }
}
