import { BLENDMODE_ONE_MINUS_SRC_ALPHA, BLENDMODE_SRC_ALPHA, BasicMaterial, BoundingBox, CULLFACE_NONE, Color, EVENT_MOUSEDOWN, EVENT_MOUSEMOVE, EVENT_MOUSEUP, Entity, Layer, Mesh, MeshInstance, PRIMITIVE_LINESTRIP, PROJECTION_PERSPECTIVE, Picker, Plane, Quat, Ray, RenderComponent, SORTMODE_BACK2FRONT, ScriptType, Vec3, math, registerScript } from "playcanvas";

export class Gizmo extends ScriptType {
    static register() {
        this.setDefaultPrototype();
        registerScript(Gizmo, "gizmo");
        this.setDefaultAttribute();
    }

    static setDefaultAttribute() {
        Gizmo.attributes.add('type', {
            type: 'number',
            enum: [
                { 'Translate': 0 },
                { 'Rotate': 1 },
                { 'Scale': 2 }
            ],
            default: 0
        });
        Gizmo.attributes.add('model', {
            type: 'entity',
            description: 'Selected model'
        });
    }

    static setDefaultPrototype() {
        const arrowRadius = 0.4;
        const GIZMO_MASK = 8;

        const gizmoSize = 0.4;
        const posCameraLast = new Vec3();
        const vecA = new Vec3();

        function createMaterial(color: Color) {
            const material = new BasicMaterial();
            material.color = color;
            if (color.a !== 1) {
                // @ts-ignore
                material.blend = true;
                // @ts-ignore
                material.blendSrc = BLENDMODE_SRC_ALPHA;
                // @ts-ignore
                material.blendDst = BLENDMODE_ONE_MINUS_SRC_ALPHA;
            }
            material.cull = CULLFACE_NONE;
            material.update();
            return material;
        }
        // @ts-ignore
        function createShapeMesh(name, type, tags, material, layers, pos, rot, scl) {
            const shape = new Entity(name);
            shape.addComponent('render', {
                castShadows: false,
                castShadowsLightmap: false,
                layers: layers,
                material: material,
                receiveShadows: false,
                type: type
            });
            shape.render.meshInstances[0].mask = GIZMO_MASK;
            shape.tags.add(tags);
            shape.setLocalPosition(pos);
            shape.setLocalEulerAngles(rot);
            shape.setLocalScale(scl);
            return shape;
        }

        // @ts-ignore
        function createLineMesh(graphicsDevice, name, tags, points, material, layers) {
            const mesh = new Mesh(graphicsDevice);
            mesh.setPositions(points);
            mesh.update(PRIMITIVE_LINESTRIP);

            const meshInstance = new MeshInstance(mesh, material);
            // meshInstance.mask = GIZMO_MASK;

            const shape = new Entity(name);
            shape.addComponent('render', {
                castShadows: false,
                castShadowsLightmap: false,
                layers: layers,
                material: material,
                meshInstances: [meshInstance],
                receiveShadows: false
            });
            shape.tags.add(tags);
            return shape;
        }
        function collectMeshInstances(entity: Entity) {
            const meshInstances: Array<MeshInstance> = [];
            if (entity) {
                const components = entity.findComponents("render");
                for (let i = 0; i < components.length; i++) {
                    const render = components[i] as RenderComponent;
                    if (render.meshInstances) {
                        for (let m = 0; m < render.meshInstances.length; m++) {
                            const meshInstance = render.meshInstances[m];
                            meshInstances.push(meshInstance);
                        }
                    }
                }
            }
            return meshInstances;
        }

        function getEntityBoundingBox(entity: Entity) {
            const entityMesh = collectMeshInstances(entity);
            if (entityMesh.length > 0) {
                const box = new BoundingBox();
                box.copy(entityMesh[0].aabb);
                for (let i = 1; i < entityMesh.length; i++) {
                    box.add(entityMesh[i].aabb);
                }
                return box;
            }
        }

        // @ts-ignore
        Gizmo.prototype.createGizmo = function () {
            const materials = {
                yellow: {
                    opaque: createMaterial(Color.YELLOW),
                    semitransparent: createMaterial(new Color(1, 1, 0, 0.25)),
                    transparent: createMaterial(new Color(1, 1, 0, 0))
                },
                red: {
                    opaque: createMaterial(Color.RED),
                    semitransparent: createMaterial(new Color(1, 0, 0, 0.25)),
                    transparent: createMaterial(new Color(1, 0, 0, 0))
                },
                green: {
                    opaque: createMaterial(Color.GREEN),
                    semitransparent: createMaterial(new Color(0, 1, 0, 0.25)),
                    transparent: createMaterial(new Color(0, 1, 0, 0))
                },
                blue: {
                    opaque: createMaterial(Color.BLUE),
                    semitransparent: createMaterial(new Color(0, 0, 1, 0.25)),
                    transparent: createMaterial(new Color(0, 0, 1, 0))
                }
            };
            this.materials = materials;

            const root = new Entity('Gizmo Root');

            this.partAxis = new Map();

            const device = this.app.graphicsDevice;
            const layers = [this.layerGizmo.id];
            let part;

            // plane x
            part = createShapeMesh('planeYZ', 'plane', ['gizmo', 'plane'], materials.red.semitransparent, layers, new Vec3(0, 0.4, 0.4), new Vec3(90, -90, 0), new Vec3(0.8, 0.8, 0.8));
            this.partAxis.set(part, 'yz');
            root.addChild(part);
            this.planeYZ = part;

            part = createLineMesh(device, 'planeYZLines', ['gizmo'], [0, 0, 0.8, 0, 0.8, 0.8, 0, 0.8, 0], materials.red.opaque, layers);
            this.partAxis.set(part, 'yz');
            root.addChild(part);
            this.planeYZLines = part;

            // plane y
            part = createShapeMesh('planeXZ', 'plane', ['gizmo', 'plane'], materials.green.semitransparent, layers, new Vec3(-0.4, 0, 0.4), new Vec3(0, 0, 0), new Vec3(0.8, 0.8, 0.8));
            this.partAxis.set(part, 'xz');
            root.addChild(part);
            this.planeXZ = part;

            part = createLineMesh(device, 'planeXZLines', ['gizmo'], [0.8, 0, 0, 0.8, 0, 0.8, 0, 0, 0.8], materials.green.opaque, layers);
            this.partAxis.set(part, 'xz');
            root.addChild(part);
            this.planeXZLines = part;

            // plane z
            part = createShapeMesh('planeXY', 'plane', ['gizmo', 'plane'], materials.blue.semitransparent, layers, new Vec3(-0.4, 0.4, 0), new Vec3(90, 0, 0), new Vec3(0.8, 0.8, 0.8));
            this.partAxis.set(part, 'xy');
            root.addChild(part);
            this.planeXY = part;

            part = createLineMesh(device, 'planeXYLines', ['gizmo'], [0, 0.8, 0, 0.8, 0.8, 0, 0.8, 0, 0], materials.blue.opaque, layers);
            this.partAxis.set(part, 'xy');
            root.addChild(part);
            this.planeXYLines = part;

            // line x
            part = createLineMesh(device, 'axisX', ['gizmo'], [-0.5, 0, 0, 0.5, 0, 0], materials.red.opaque, layers);
            this.partAxis.set(part, 'x');
            root.addChild(part);
            this.axisX = part;

            part = createShapeMesh('axisXPick', 'cylinder', ['gizmo'], materials.red.transparent, layers, new Vec3(1.6, 0, 0), new Vec3(90, 90, 0), new Vec3(arrowRadius, 0.8, arrowRadius));
            this.partAxis.set(part, 'x');
            root.addChild(part);
            this.axisXPick = part;

            part = createShapeMesh('arrowX', 'cone', ['gizmo'], materials.red.opaque, layers, new Vec3(2.3, 0, 0), new Vec3(90, 90, 0), new Vec3(arrowRadius, 0.6, arrowRadius));
            this.partAxis.set(part, 'x');
            root.addChild(part);
            this.arrowX = part;

            // line y
            part = createLineMesh(device, 'axisY', ['gizmo'], [0, -0.5, 0, 0, 0.5, 0], materials.green.opaque, layers);
            this.partAxis.set(part, 'y');
            root.addChild(part);
            this.axisY = part;

            part = createShapeMesh('axisYPick', 'cylinder', ['gizmo'], materials.green.transparent, layers, new Vec3(0, 1.6, 0), new Vec3(0, 0, 0), new Vec3(arrowRadius, 0.8, arrowRadius));
            this.partAxis.set(part, 'y');
            root.addChild(part);
            this.axisYPick = part;

            part = createShapeMesh('arrowY', 'cone', ['gizmo'], materials.green.opaque, layers, new Vec3(0, 2.3, 0), new Vec3(0, 0, 0), new Vec3(arrowRadius, 0.6, arrowRadius));
            this.partAxis.set(part, 'y');
            root.addChild(part);
            this.arrowY = part;

            // line z
            part = createLineMesh(device, 'axisZ', ['gizmo'], [0, 0, -0.5, 0, 0, 0.5], materials.blue.opaque, layers);
            this.partAxis.set(part, 'z');
            root.addChild(part);
            this.axisZ = part;

            part = createShapeMesh('axisZPick', 'cylinder', ['gizmo'], materials.blue.transparent, layers, new Vec3(0, 0, 1.6), new Vec3(90, 0, 0), new Vec3(arrowRadius, 0.8, arrowRadius));
            this.partAxis.set(part, 'z');
            root.addChild(part);
            this.axisZPick = part;

            part = createShapeMesh('arrowZ', 'cone', ['gizmo'], materials.blue.opaque, layers, new Vec3(0, 0, 2.3), new Vec3(90, 0, 0), new Vec3(arrowRadius, 0.6, arrowRadius));
            this.partAxis.set(part, 'z');
            root.addChild(part);
            this.arrowZ = part;

            root.setPosition(this.model.getPosition());
            root.setRotation(this.model.getRotation());
            this.app.root.addChild(root);
            this.gizmoRoot = root;
        };

        // @ts-ignore
        Gizmo.prototype.createLayer = function () {
            this.layerGizmo = new Layer({
                enabled: true,
                name: 'Gizmo',
                passThrough: true,
                overrideClear: true,
                clearDepthBuffer: true,
                opaqueSortMode: SORTMODE_BACK2FRONT,
                transparentSortMode: SORTMODE_BACK2FRONT
            });
            this.app.scene.layers.push(this.layerGizmo);
            const cameraLayers = this.entity.camera.layers;
            cameraLayers.push(this.layerGizmo.id);
            this.entity.camera.layers = cameraLayers;
        };

        // initialize code called once per entity
        Gizmo.prototype.initialize = function () {
            this.createLayer();
            this.createGizmo();

            this.picker = new Picker(this.app, this.app.graphicsDevice.width, this.app.graphicsDevice.height);

            // @ts-ignore
            this.app.graphicsDevice.on('resize', (width: number, height: number) => {
                this.picker.resize(width, height);
            });
            this.hoverAxis = null;

            this.raycast = new Ray();

            this.gizmoRay = new Ray();

            this.isDragging = false;

            this.selectedGizmo = undefined;

            this.gizmoRayPos = new Vec3();

            this.planeGizmo = new Plane();

            this.gizmoPlaneHitPos = new Vec3();
            // @ts-ignore
            this.app.mouse.on(EVENT_MOUSEMOVE, this.onMouseMove, this);
            // @ts-ignore
            this.app.mouse.on(EVENT_MOUSEDOWN, this.onMouseDown, this);
            // @ts-ignore
            this.app.mouse.on(EVENT_MOUSEUP, this.onMouseUp, this);
            this.on("destroy", () => {
                // @ts-ignore
                this.app.mouse.off(EVENT_MOUSEMOVE, this.onMouseMove, this);
                // @ts-ignore
                this.app.mouse.off(EVENT_MOUSEDOWN, this.onMouseDown, this);
                // @ts-ignore
                this.app.mouse.off(EVENT_MOUSEUP, this.onMouseUp, this);
                this.app.root.removeChild(this.gizmoRoot);
                this.gizmoRoot.destroy();
            });
        };

        // @ts-ignore
        Gizmo.prototype.getGizmoByRaycast = function (e: MouseEvent) {
            const selection: Entity[] = [];
            this.entity.camera.screenToWorld(e.x, e.y, this.entity.camera.farClip, this.raycast.direction);
            this.raycast.origin.copy(this.entity.getPosition());
            this.raycast.direction.sub(this.raycast.origin).normalize();

            if (this.gizmoRoot && this.gizmoRoot.children.length > 0) {
                this.gizmoRoot.children.forEach((gizmoChild: Entity) => {
                    const gizmoChildBox = getEntityBoundingBox(gizmoChild);
                    const result = gizmoChildBox.intersectsRay(this.raycast, this.gizmoPlaneHitPos);
                    if (result) {
                        selection.push(gizmoChild);
                    }
                });
            }
            return selection;
        };

        // @ts-ignore
        Gizmo.prototype.onHoverGizmo = function (e: MouseEvent) {
            const selection: Entity[] = this.getGizmoByRaycast(e);

            let hoverAxis = null;
            for (let i = 0; i < selection.length; ++i) {
                    // @ts-ignore
                const hoverEntity = selection[i];
                hoverAxis = this.partAxis.get(hoverEntity);
                if (hoverAxis) {
                    break;
                }
            }

            const materials = this.materials;

            // If the hovered entity has changed...
            if (this.hoverAxis !== hoverAxis) {
                    // If there was previously selected gizmo component...
                if (this.hoverAxis) {
                        // ...reset its material back to the original one
                    if (this.hoverAxis === 'x') {
                        this.axisX.render.meshInstances[0].material = materials.red.opaque;
                        this.arrowX.render.material = materials.red.opaque;
                    } else if (this.hoverAxis === 'y') {
                        this.axisY.render.meshInstances[0].material = materials.green.opaque;
                        this.arrowY.render.material = materials.green.opaque;
                    } else if (this.hoverAxis === 'z') {
                        this.axisZ.render.meshInstances[0].material = materials.blue.opaque;
                        this.arrowZ.render.material = materials.blue.opaque;
                    } else if (this.hoverAxis === 'yz') {
                        this.planeYZLines.render.meshInstances[0].material = materials.red.opaque;
                        this.planeYZ.render.meshInstances[0].material = materials.red.semitransparent;
                    } else if (this.hoverAxis === 'xz') {
                        this.planeXZLines.render.meshInstances[0].material = materials.green.opaque;
                        this.planeXZ.render.meshInstances[0].material = materials.green.semitransparent;
                    } else if (this.hoverAxis === 'xy') {
                        this.planeXYLines.render.meshInstances[0].material = materials.blue.opaque;
                        this.planeXY.render.meshInstances[0].material = materials.blue.semitransparent;
                    }
                }

                this.hoverAxis = hoverAxis;

                    // If we're hovering over a gizmo component...
                if (this.hoverAxis) {
                    if (this.hoverAxis === 'x') {
                        this.axisX.render.meshInstances[0].material = materials.yellow.opaque;
                        this.arrowX.render.material = materials.yellow.opaque;
                    } else if (this.hoverAxis === 'y') {
                        this.axisY.render.meshInstances[0].material = materials.yellow.opaque;
                        this.arrowY.render.material = materials.yellow.opaque;
                    } else if (this.hoverAxis === 'z') {
                        this.axisZ.render.meshInstances[0].material = materials.yellow.opaque;
                        this.arrowZ.render.material = materials.yellow.opaque;
                    } else if (this.hoverAxis === 'yz') {
                        this.planeYZLines.render.meshInstances[0].material = materials.yellow.opaque;
                        this.planeYZ.render.meshInstances[0].material = materials.yellow.semitransparent;
                    } else if (this.hoverAxis === 'xz') {
                        this.planeXZLines.render.meshInstances[0].material = materials.yellow.opaque;
                        this.planeXZ.render.meshInstances[0].material = materials.yellow.semitransparent;
                    } else if (this.hoverAxis === 'xy') {
                        this.planeXYLines.render.meshInstances[0].material = materials.yellow.opaque;
                        this.planeXY.render.meshInstances[0].material = materials.yellow.semitransparent;
                    }
                }
                this.entity.fire("onHoverGizmo");
            }
        };

        // @ts-ignore
        Gizmo.prototype.onMouseMove = function (e: MouseEvent) {
            if (this.isDragging) {
                if (this.selectedGizmo) {
                    this.entity.camera.screenToWorld(e.x, e.y, this.entity.camera.farClip, this.gizmoRay.direction);
                    this.gizmoRay.origin.copy(this.entity.getPosition());
                    this.gizmoRay.direction.sub(this.gizmoRay.origin).normalize();
                    const isIntersectPlane = this.planeGizmo.intersectsRay(this.gizmoRay, this.gizmoRayPos);
                    if (isIntersectPlane && !this.gizmoPlaneHitPos.equals(this.gizmoRayPos)) {
                        let speed = 0.0005;
                        if (this.gizmoRayPos.x <= this.gizmoPlaneHitPos.x && this.hoverAxis === 'x' || this.gizmoRayPos.y <= this.gizmoPlaneHitPos.y && this.hoverAxis === 'y' || this.gizmoRayPos.z <= this.gizmoPlaneHitPos.z && this.hoverAxis === 'z') {
                            speed = -speed;
                        }
                        this.onScaleModelByAxis(this.partAxis.get(this.selectedGizmo), speed);
                        this.gizmoPlaneHitPos.copy(this.gizmoRayPos);
                    }
                }
            } else {
                this.onHoverGizmo(e);
            }
        };

        // @ts-ignore
        Gizmo.prototype.onMouseUp = function (e: MouseEvent) {
            this.isDragging = false;
            if (this.selectedGizmo) {
                this.selectedGizmo = undefined;
            }
            this.entity.fire("onEndDragging");
        };

        // @ts-ignore
        Gizmo.prototype.onMouseDown = function (e: MouseEvent) {
            this.isDragging = true;
            this.onHoverGizmo(e);
            const selection: Entity[] = this.getGizmoByRaycast(e);
            if (selection.length > 0) {
                this.selectedGizmo = selection[0];
                switch (this.hoverAxis) {
                    case 'x':
                    case 'z':
                        this.planeGizmo.setFromPointNormal(this.gizmoRoot.getLocalPosition(), this.gizmoRoot.up.clone());
                        break;
                    case 'y':
                        this.planeGizmo.setFromPointNormal(this.gizmoRoot.getLocalPosition(), this.gizmoRoot.right.clone());
                        break;
                    default:
                        break;
                }
                this.entity.fire("onStartDragging");
            }
        };

        // @ts-ignore
        Gizmo.prototype.onScaleModelByAxis = function (axis: string, speed: number) {
            const modelScale = this.model.getLocalScale();
            modelScale[axis] += speed;
            this.model.setLocalScale(modelScale);
            this.model.fire("onModelScale");
        };

        // update code called every frame
        Gizmo.prototype.update = function (dt: number) {
            if (this.gizmoRoot && this.model) {
                this.gizmoRoot.setPosition(this.model.getPosition());
                this.gizmoRoot.setRotation(this.model.getRotation());
            }
            const camera = this.entity;
            const posCamera = camera.getPosition();
            const quat = new Quat();

            quat.copy(this.model.getRotation()).invert();

            posCameraLast.copy(posCamera);

            const posGizmo = this.model.getPosition();
            let scale = 1;

            // scale to screen space
            if (camera.camera.projection === PROJECTION_PERSPECTIVE) {
                const dot = vecA.copy(posGizmo).sub(posCamera).dot(camera.forward);
                const denom = 1280 / (2 * Math.tan(camera.camera.fov * math.DEG_TO_RAD / 2));
                scale = Math.max(0.0001, (dot / denom) * 150) * gizmoSize;
            } else {
                scale = camera.camera.orthoHeight / 3 * gizmoSize;
            }
            this.gizmoRoot.setLocalScale(scale, scale, scale);

            // calculate viewing angle
            vecA.copy(posCamera).sub(posGizmo).normalize();

            // rotate vector by gizmo rotation
            quat.transformVector(vecA, vecA);

            // swap sides to face camera
            // x
            this.planeYZ.setLocalPosition(0, (vecA.y > 0) ? 0.4 : -0.4, (vecA.z > 0) ? 0.4 : -0.4);
            this.planeYZLines.setLocalScale(1, (vecA.y > 0) ? 1 : -1, (vecA.z > 0) ? 1 : -1);
            this.axisXPick.setLocalPosition((vecA.x > 0) ? 1.5 : 1.1, 0, 0);
            this.axisXPick.setLocalScale(arrowRadius, (vecA.x > 0) ? 1 : 1.8, arrowRadius);

            // y
            this.planeXZ.setLocalPosition((vecA.x > 0) ? 0.4 : -0.4, 0, (vecA.z > 0) ? 0.4 : -0.4);
            this.planeXZLines.setLocalScale((vecA.x > 0) ? 1 : -1, 1, (vecA.z > 0) ? 1 : -1);
            this.axisYPick.setLocalPosition(0, (vecA.y > 0) ? 1.5 : 1.1, 0);
            this.axisYPick.setLocalScale(arrowRadius, (vecA.y > 0) ? 1 : 1.8, arrowRadius);
            // z
            this.planeXY.setLocalPosition((vecA.x > 0) ? 0.4 : -0.4, (vecA.y > 0) ? 0.4 : -0.4, 0);
            this.planeXYLines.setLocalScale((vecA.x > 0) ? 1 : -1, (vecA.y > 0) ? 1 : -1, 1);
            this.axisZPick.setLocalPosition(0, 0, (vecA.z > 0) ? 1.5 : 1.1);
            this.axisZPick.setLocalScale(arrowRadius, (vecA.z > 0) ? 1 : 1.8, arrowRadius);

            // hide pslane if viewed from glancing angle
            this.planeYZ.enabled = this.planeYZLines.enabled = Math.abs(vecA.x) > 0.15;
            this.planeXZ.enabled = this.planeXZLines.enabled = Math.abs(vecA.y) > 0.15;
            this.planeXY.enabled = this.planeXYLines.enabled = Math.abs(vecA.z) > 0.15;

            // haxesrows if viewed from glancing angle
            this.axisX.enabled = this.axisXPick.enabled = this.arrowX.enabled = !(Math.abs(vecA.z) <= 0.15 && Math.abs(vecA.y) <= 0.15);
            this.axisY.enabled = this.axisYPick.enabled = this.arrowY.enabled = !(Math.abs(vecA.x) <= 0.15 && Math.abs(vecA.z) <= 0.15);
            this.axisZ.enabled = this.axisZPick.enabled = this.arrowZ.enabled = !(Math.abs(vecA.x) <= 0.15 && Math.abs(vecA.y) <= 0.15);

            this.axisX.setLocalPosition((vecA.x > 0) ? 1.5 : 1.1, 0, 0);
            this.axisX.setLocalScale((vecA.x > 0) ? 1 : 1.8, 1, 1);

            this.axisY.setLocalPosition(0, (vecA.y > 0) ? 1.5 : 1.1, 0);
            this.axisY.setLocalScale(1, (vecA.y > 0) ? 1 : 1.8, 1);

            this.axisZ.setLocalPosition(0, 0, (vecA.z > 0) ? 1.5 : 1.1);
            this.axisZ.setLocalScale(1, 1, (vecA.z > 0) ? 1 : 1.8);
        };
    }
}
